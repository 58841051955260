import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from '../pages/user/home/Main';

function RouterUser(props) {
    return (
      <Routes>
        <Route exact path="/:tenantId/:userId" element={ <Main /> } />
      </Routes>
    );
}

export default RouterUser;
import React, { useState } from 'react';
import SKUItem from './SKUItem';
import { ChatActions } from "../../../actions/common/ChatActions";
import "./mySKU.css";

const SubmitButton = ({ onClick }) => (
  <div className="overlap">
    <button className="text-wrapper-2" onClick={onClick}>
      发送选择的SKU
    </button>
  </div>
);

function MySKU(props) {
  const { skus, closeModal, cnuuid, cnchannelurl } = props;
  const [selectedSkus, setSelectedSkus] = useState([]);

  const handleSelectSku = (sku) => {
    setSelectedSkus((prevSelected) => {
      const isAlreadySelected = prevSelected.some(item => item.code === sku.skuId);
      
      if (isAlreadySelected) {
        return prevSelected.filter(item => item.code !== sku.skuId); // 取消选择
      } else {
        return [
          ...prevSelected,
          {
            attribute: sku.attributeName,
            price: sku.price,
            code: sku.skuId,
            imgSrc: sku.skuImageUrl,
          }
        ]; // 添加选择
      }
    });
  };

  const handleSubmit = async () => {
    console.log("选择的SKU:", selectedSkus);

    const params = {
      message: '',
      message_type: 'MESG',
      user_id: cnuuid,
      custom_type: 'sku',
      data: JSON.stringify(selectedSkus),
    };

    await ChatActions._sendMsg(cnchannelurl, params);
    closeModal();
  };

  return (
    <div className="frame">
      <div className="div">
        <div className="text-wrapper-3">请选择咨询的SKU</div>
        <div className="overlap-group" style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {skus.map((sku) => (
            <SKUItem 
              key={sku.skuId} // 确保每个 SKUItem 有唯一的 key
              attribute={sku.attributeName} 
              price={sku.price} 
              code={sku.skuId} 
              imgSrc={sku.skuImageUrl} 
              isSelected={selectedSkus.some(item => item.code === sku.skuId)} // 判断是否选中
              onSelect={() => handleSelectSku(sku)} // 处理选择
            />
          ))}
        </div>
        <SubmitButton onClick={handleSubmit} />
      </div>
    </div>
  );
}

export default MySKU;

// reducers/channelReducer.js
const initialChannelState = {
    current_cnchannel: null,
};

export const cnchannelReducer = (state = initialChannelState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_CNCHANNEL':
            return {
                ...state,
                current_cnchannel: action.payload,
            };
        default:
            return state;
    }
};

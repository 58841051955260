const API_HOST =  'https://cnchat.gngnetwork.com';

export const CnchatApi = {
    cnchat_info: API_HOST + '/api/chat/info/',//get

    recently_viewed: API_HOST + '/api/recently/viewed/products', //get
    product_info: API_HOST + '/api/my/product/library', //get
    order_info: API_HOST + '/api/my/order', //get

    view_count: id => API_HOST + `/api/faqs/${id}/increment-view-count`,//post
    like_vote: id => API_HOST + `/api/faqs/${id}/increment-like-count`,//post
    dislike_vote: id => API_HOST + `/api/faqs/${id}/increment-dislike-count`,//post
    unread_messages: user_id => API_HOST + `/api/messages/${user_id}/unread`,//get
    faqs_info: API_HOST + '/api/category/faqs/index',//get
    group_assign: user_id => API_HOST + `/api/user/assign/channel/${user_id}`//post
};
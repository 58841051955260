// reducers/sendbirdReducer.js
const initialSendbirdState = {
    cnsendbird: null,
};

export const cnsendbirdReducer = (state = initialSendbirdState, action) => {
    switch (action.type) {
        case 'SET_CNSENDBIRD':
            return {
                ...state,
                cnsendbird: action.payload,
            };
        default:
            return state;
    }
};

import axios from 'axios';
import { jsonToUrlParam } from './functions';
import { APP_ID, ACCESS_TOKEN } from '../pages/user/home/const';
export const SendbirdReq = {
    host: () => {
        //const sendbirdConfig = JSON.parse(localStorage.getItem('sendbird'));
        return `https://api-${APP_ID}.sendbird.com/v3`;
    },

    /**
     * 组合 header 数据
     * @param {*} params {extraHeader}
     * @returns Json Object
     */
     headers: (params) => {
        let extraHeader = params.extraHeader ? params.extraHeader : {};
        //const sendbirdConfig = JSON.parse(localStorage.getItem('sendbird'));

        return {
            ...extraHeader,
            'Content-Type': 'application/json; charset=utf8',
            'Api-Token': ACCESS_TOKEN,
        }
    },
    
    /**
     * GET 模式请求 api
     * @param {*} params {url, headers}
     * @returns Json Object
     */
    get: async (params) => {
        try {
            let url = params.url;
    
            // 如果有查询参数，则将其转化为 URL 参数并拼接到 URL 上
            if (params.datas) {
                url += '?' + jsonToUrlParam(params.datas);
            }
            console.log(url);
            // 发起 GET 请求
            let result = await axios.get(
                SendbirdReq.host() + url,
                {headers: SendbirdReq.headers({extraHeader: params.headers})}
            );
    
            return result.data;
    
        } catch (error) {
            // 错误处理，返回详细的错误信息
            if (error.response) {
                // 服务端返回了错误响应
                return {
                    code: error.response.status,
                    message: error.response.data.message || 'Server responded with an error',
                    details: error.response.data || null
                };
            } else if (error.request) {
                // 请求已经发出，但没有收到响应
                return {
                    code: 'E400102',
                    message: 'No response from server',
                    details: error.request
                };
            } else {
                // 其他错误，如代码配置错误等
                return {
                    code: 'E400103',
                    message: 'Error in setting up request',
                    details: error.message
                };
            }
        }
    },

    /**
     * POST 模式请求 api
     * @param {*} params {url, datas, headers}
     * @returns Json Object
     */
    post: async (params) => {
        let result = await axios.post(
            SendbirdReq.host() + params.url,
            params.datas,
            {headers: SendbirdReq.headers({extraHeader: params.headers})}
        );

        return result.data;
    },

    /**
     * PUT 模式请求 api
     * @param {*} params {url, datas, headers}
     * @returns Json Object
     */
    put: async (params) => {
        let result = await axios.put(
            SendbirdReq.host() + params.url,
            params.datas,
            {headers: SendbirdReq.headers({extraHeader: params.headers})}
        );

        return result.data;
    },

    /**
     * DELETE 模式请求 api
     * @param {*} params {url, datas, headers}
     * @returns Json Object
     */
    delete: async (params) => {
        let param = params.datas ? params.datas : {}
        let result = await axios.delete(
            SendbirdReq.host() + params.url,
            {
                data: param,
                headers: SendbirdReq.headers({extraHeader: params.headers})
            }
        );

        return result.data;
    }
}
import React, { useState } from "react";
import { connect } from 'react-redux';
import { sendBotMessage } from '../actions/common/sendbird';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { Card, CardContent, Avatar } from "@mui/material";
import { ChatActions } from "../actions/common/ChatActions";
import { ChannelActions } from "../actions/common/ChannelActions";
import './FAQBot.css'; 


function NotiMessage(props) {
  const message = props.message;
  const channel = props.current_cnchannel;
  //const nickname = message.sender ? message.sender.nickname : '';
  //const [mode, setMode] = useState('chatbot'); // 当前模式，默认为 'chatbot'


  // 发送Noti函数
  const sendNotiMessage = (text) => {
    const messageParams = {
      "message": text,
      "channel_url": channel.url,
      "custom_type": "bot_notification"
    };
  
    try {
      const sentMessage = sendBotMessage(messageParams);
      console.log('Message sent by cnchat_bot_1:', sentMessage);
    } catch (error) {
      console.error('Failed to send notification by cnchat_bot_1:', error);
    }
  };


  const handleButtonClick = async () => {
    
    if (channel.customType === 'end') return false;

    try {
      // 调用外部接口

      const res = await ChannelActions.updateChannel(channel, { custom_type: 'end'});
      console.log(res);
      // 在这里处理成功响应，比如显示成功提示等
    } catch (error) {
      console.error('调用接口失败:', error);
      // 在这里处理错误，比如显示错误提示等
    }
  };



  return (
    <div className="noti-bot">

<Card sx={ { boxShadow : 'none' } } >
<CardContent>
<div className="t20 cb"></div>
            <button className="button3 fl" type="button" onClick={handleButtonClick}>
              <img src="img/bu1.png" className="t01 r08" alt="结束服务" />
              结束服务
            </button>

            <div class="cb t20 ac">
                <span class="span2">{message.message} 将为您服务</span>
                <div class="t15 cb"></div>
                <div class="icon2">{ <Avatar alt="Us" src="img/icon2.png" /> }</div>
                <div class="cont_text2 t05">
                    <strong>CNINSIDER {message.message}</strong>
                    人工服务
                </div>
            </div>
</CardContent>
</Card>

    </div>
  );

}


// 从 Redux 中映射 state 到 props
const mapStateToProps = ({
  cnchannelReducer: { current_cnchannel },
  cnuserReducer: { cnuuid }
}) => ({
  current_cnchannel,
  cnuuid
});

export default connect(mapStateToProps)(NotiMessage);

import "./chatApp.css";
import React, { useEffect, useState } from "react";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import MainComponent from "./MainComponent";
import "@sendbird/uikit-react/dist/index.css";
import SendbirdChat from "@sendbird/chat";
import { GroupChannelModule } from "@sendbird/chat/groupChannel";
import { ChatActions } from "../../../actions/common/ChatActions";
import { APP_ID,ACCESS_TOKEN } from './const';
import kr from 'date-fns/locale/ko';
import zhCN from 'date-fns/locale/zh-CN';
import krTranslation from './translations/kr.json';
import cnTranslation from './translations/cn.json';
import { Spin } from 'antd';

function ChatApp({ tenantId, userId, updateSharedData }) {
  const [sendbird, setSendbird] = useState(null);
  const [channelUrl, setchannelUrl] = useState(null);
  const [cnUserId, setCnUserId] = useState(null);
  const [loading, setLoading] = useState(true);

  const myColorSet = {
    '--sendbird-light-primary-500': '#FF4000',
    '--sendbird-light-primary-400': '#d64718',
    '--sendbird-light-primary-300': '#aa583d',
    '--sendbird-light-primary-200': '#6d5046',
    '--sendbird-light-primary-100': '#EBEBEB',
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const faqsInfo = await ChatActions.getFaqsInfo();
        if (faqsInfo.status === '200')
        {
          localStorage.setItem('faqsData', JSON.stringify(faqsInfo.data));
        }
        

        const data = { tenantId: tenantId, userId: userId };
        const chatInfo = await ChatActions.getChatInfo(data);  
        console.log(chatInfo);

        if (chatInfo.status === '200') {

          updateSharedData(chatInfo.data.setting);
          setchannelUrl(chatInfo.data.channel_url);

        } else {
          console.error(`channel url 接口调用失败: ${chatInfo.message}`);
          return;
        }

        const sb = SendbirdChat.init({
          appId: APP_ID,
          modules: [new GroupChannelModule()],
        });
        await sb.connect(userId);
        
        setSendbird(sb);        
        setCnUserId(userId);

      } catch (error) {
        console.error(`Sendbird 初始化失败: ${error.message}`);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [tenantId, userId]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <Spin size="large" />
    </div>
    );
  }
  if (!cnUserId) {
    return <div>Error: User ID is not available.</div>; // 处理未获取到 userId 的情况
  }

  return (
    <div className="App">
      <SendbirdProvider
        appId={APP_ID}
        userId={cnUserId}
        accessToken={ACCESS_TOKEN}
        colorSet={myColorSet}
        stringSet={cnTranslation}
        dateLocale={zhCN}
      >
        <MainComponent userId={cnUserId} sb={sendbird} channelUrl={channelUrl} />
      </SendbirdProvider>
    </div>
  );
}

export default ChatApp;

import React, { useState } from 'react';
import { Card, Avatar, CardContent } from "@mui/material";
import '../pages/user/side/css/productList.css';

function formatTimestamp(timestamp) {
	const date = new Date(timestamp);
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	return `${hours}:${minutes}`; // 返回时:分格式
  }


const HisMyOrderMessage = (props) => {
	const message = props.message;
	const nickname = message.sender ? message.sender.nickname : '';
	console.log(message);
  const order = JSON.parse(message.data);
	const formattedTime = formatTimestamp(message.createdAt);


  return (
<div>
      <Card sx={ { boxShadow : 'none' } } >


<CardContent>



		<div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            size={37}
            alt=""
            src={message.sender.plainProfileUrl}
            style={{ marginRight: '8px' }} // 添加右边距以分隔头像和文本
          />
          <div>
            <strong className="cont_text2">{nickname}</strong>
            <span className="cont_text1" style={{ marginLeft: '8px' }}>{formattedTime}</span>
          </div>
        </div>




<ul className="ufl" style={{ listStyleType: 'none', padding: 0 }}>
  <li style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>


  <div className="l10">
      
      <div>订单编号 : {order.taskno} </div>
        <div className="t05">
          <span className="red">¥ {order.amount} </span>
          <span className="cont_text4 l15">总数量: {order.quantitynum} </span>
        </div>
      </div>


  </li>
</ul>



















</CardContent>

</Card>
    </div>


  );
};


export default HisMyOrderMessage;
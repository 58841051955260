import { SendbirdApi } from '../../utils/sendbird.api';
import { SendbirdReq } from '../../utils/SendbirdReq';

export const ChannelActions = {
    //首页channel数据列表，和channelListData内容一样，但不能合并（数据请求未完成的情况下页面切换时数据一直叠加，导致数据不准确）
    _channelMainData: [],

    //channel组件的channel数据列表
    _channelListData: [],

    //保存所有channel数据和channel状态统计数据
    _channelAll: {},


    /**获取channel 列表 */
    channelList: async () => await ChannelActions._channels(ChannelActions._channelListData, 'true'),

    /**
     * 获取所有channel信息及channel状态的统计数据
     * @param Array listContainer channel数据列表容器
     * @param String custom_types channel聊天状态
     * @param String members channel数据是否带member
     * @param String next_token
     * @param Boolean init 是否初始化容器（用于递归）
     * @returns Array
     */


    /**根据channel_url获取指定channel */
    getChannel: async (channel_url) => {
        let datas = {};
        let response;

        try {
            response = await SendbirdReq.get({
                url: SendbirdApi.group_channel(channel_url),
                datas: { show_member: true }
            });
        } catch (err) {
            datas['code'] = err.response.data.code;
            datas['message'] = err.response.data.message;
            return datas;
        }

        return response;
    },

    /**
     * 所有消息设置为已读
     * @param {*} channel_url 
     * @param {*} users 
     * @returns 
     */
    markAsRead: async (channel_url, users) => {
        if (users.length === 0) return false;

        const user = users.shift();

        await SendbirdReq.put({
            url: SendbirdApi.mark_as_read(channel_url),
            datas: {
                user_id: user.user_id
            }
        });

        ChannelActions.markAsRead(channel_url, users);
    },

    /**
     * 在sendbird中channel状态修改
     * @param {*} channel 
     * @param {*} datas 
     */
    updateChannel: async (channel, datas) => {
        const response = await SendbirdReq.put({
            url: SendbirdApi.group_channels(channel.url),
            datas: datas
        });

        return response;
    },

    /**
     * channel中添加管理员
     * @param {*} channel_url
     * @param {*} user_ids 
     */
    inviteChannel: async (channel_url, user_ids) => {
        return await SendbirdReq.post({
            url: SendbirdApi.invite(channel_url),
            datas: { user_ids }
        });
    },

    /**
     * channel中删除管理员
     * @param {*} channel_url
     * @param {*} user_ids 
     */
    leaveChannel: async (channel_url, user_ids) => {
        return await SendbirdReq.put({
            url: SendbirdApi.leave(channel_url),
            datas: { user_ids }
        });
    },

    /**
     * 删除180天以上不说话的channel
     * @param {*} channel_urls 
     */
    removeChannel: async (channel_urls) => {
        for (let i = 0; i < channel_urls.length; i++) {
            await SendbirdReq.delete({
                url: SendbirdApi.delete_channel(channel_urls[i])
            });
        }

    },

    /**新创建的channel中加入bot */
    botJoinChannel: async (param) => {
        return await SendbirdReq.post({
            url: SendbirdApi.bot_join_channel(param.bot_userid),
            datas: { channel_urls: [param.channel_url] }
        });
    }
};
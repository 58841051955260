import React, { useMemo } from "react";
import AdminMessage from "../../../MessageItems/AdminMessage";
import HisFileMessage from "../../../MessageItems/HisFileMessage";
import HisUserMessage from "../../../MessageItems/HisUserMessage";
import FAQMessage from "../../../MessageItems/FAQMessage";
import BotMessage from "../../../MessageItems/BotMessage";
import HisProductMessage from "../../../MessageItems/HisProductMessage";
import HisMyProductMessage from "../../../MessageItems/HisMyProductMessage";
import HisMyOrderMessage from "../../../MessageItems/HisMyOrderMessage";
import HisSkuMessage from "../../../MessageItems/HisSkuMessage";



import "./customizedMessageItem.css";
// 自定义时间线组件
const Timeline = ({ date }) => {
  return (
    <div className="timeline">
      <hr />
      <span>{date}</span>
      <hr />
    </div>
  );
};

// 格式化日期函数
const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString(); // 根据需求自定义日期格式
};
let lastMessageDate = null;
function HisMessageItem({ key, message }) {

  // 使用 useMemo 优化组件性能，只有在 message 或 updateUserMessage 变化时重新计算
  const MessageComponent = useMemo(() => {
    if (message.sendingStatus !== "succeeded") return null; // 确保消息成功发送

console.log(message.message);

    const currentMessageDate = new Date(message.createdAt).toDateString();
    const showTimeline = lastMessageDate !== currentMessageDate;
    lastMessageDate = currentMessageDate;
    // 根据消息类型返回相应的组件
    if (message.customType === "faq_auto_reply") {
      return null;
    }
    // 根据消息类型返回相应的组件
    if (message.customType === "bot_auto_reply") {
      return null;
    }

    if (message.customType === "utis") {
      return (
        <>
          {showTimeline && <Timeline date={formatDate(message.createdAt)} />}
          <HisProductMessage message={message} />
        </>
      );
    }


    if (message.customType === "myproduct") {
      return (
        <>
          {showTimeline && <Timeline date={formatDate(message.createdAt)} />}
          <HisMyProductMessage message={message} />
        </>
      );
    }
    if (message.customType === "myorder") {
      return (
        <>
          {showTimeline && <Timeline date={formatDate(message.createdAt)} />}
          <HisMyOrderMessage message={message} />
        </>
      );
    }
    if (message.customType === "sku") {
      return (
        <>
          {showTimeline && <Timeline date={formatDate(message.createdAt)} />}
          <HisSkuMessage message={message} />
        </>
      );
    }
    if (message.isAdminMessage?.()) {
      return <AdminMessage message={message} />;
    }

    if (message.isFileMessage?.()) {
      return (
        <>
          {showTimeline && <Timeline date={formatDate(message.createdAt)} />}
          <HisFileMessage message={message} />
        </>
      );
    }

    if (message.isUserMessage?.()) {
      return (
        <>
          {showTimeline && <Timeline date={formatDate(message.createdAt)} />}
          <HisUserMessage message={message} />
        </>
      );
    }

    // 默认返回 null
    return null;
  }, [message]);

  // 返回包裹的组件及样式
  return (
    <div id={message.messageId} className="customized-message-item">
      {MessageComponent}
    </div>
  );
}

export default HisMessageItem;

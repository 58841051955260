import React, { useState } from 'react';
import { Card, Avatar, CardContent } from "@mui/material";
import { Image } from 'antd';
import './mySKU.css';

const img_url = 'https://gw.alicdn.com/tfs//a7/3f/TB1ww3yLYrpK1RjSZTESuwWAVXa.jpg_320x320q75.jpg_.webp';

const SkuMessage = (props) => {
	const {
		message,
		customerId,
	  } = props;
  const skus = JSON.parse(message.data);
  return (
<div>
      <Card sx={ { boxShadow : 'none' } } >
	  <CardContent>

	  {(message.sender.userId === customerId) && (




  
<ul className="ufl t10 fr">
			<li>
				<div className="bgd1_2">


<ul className="ufl" style={{ listStyleType: 'none', padding: 0 }}>
      {skus.map((sku) => (
        <li key={sku.code} className="sku-item">
          <div className="sku-info">
            <div className="attachment right-file">
              <Image width={120} src={sku.imgSrc} alt={sku.attribute} />
            </div>
            <div className="sku-details">
              <span className="sku-attribute">{sku.attribute}</span>
              <div className="t05">
                <span className="red r10">¥ {sku.price.toFixed(2)}</span>
                <span className="cont_text4">{sku.code}</span>
              </div>
            </div>
          </div>
        </li>
      ))}
</ul>

<div className="cb"></div>
				</div>
			</li>
			<li><div className="icon2"><Avatar alt="" src={message.sender.plainProfileUrl} /></div></li>
		</ul>



)}
          {!(message.sender.userId === customerId) && (


<ul className="ufl t20">

<li><div className="icon2" style={{'margin-left': '8px'}}><Avatar alt="" src={message.sender.plainProfileUrl} /></div></li>
			<li>
				<div className="bgd1_2">


<ul className="ufl" style={{ listStyleType: 'none', padding: 0 }}>
{skus.map((sku) => (
	<li key={sku.code} className="sku-item">
		<div className="sku-info">
		<div className="attachment right-file">
			<Image width={120} src={sku.imgSrc} alt={sku.attribute} />
		</div>
		<div className="sku-details">
			<span className="sku-attribute">{sku.attribute}</span>
			<div className="t05">
			<span className="red r10">¥ {sku.price.toFixed(2)}</span>
			<span className="cont_text4">{sku.code}</span>
			</div>
		</div>
		</div>
	</li>
	))}
</ul>

<div className="cb"></div>
				</div>
			</li>
		</ul>






)}
</CardContent>

</Card>
    </div>


  );
};


export default SkuMessage;
import React, { useState } from "react";
import { connect } from 'react-redux';
import { sendBotMessage } from '../actions/common/sendbird';

import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import './index.css';
import { message as antdMessage } from 'antd';
import { Card, CardHeader, CardContent, Typography, Avatar } from "@mui/material";
import { Menu, Dropdown, Button } from 'antd';
import { ChatActions } from "../actions/common/ChatActions";
import { ChannelActions } from "../actions/common/ChannelActions";
import './FAQBot.css'; 


function FAQMessage(props) {
  const [activeButton, setActiveButton] = useState(null);
  const message = props.message;
  const channel = props.current_cnchannel;
  const nickname = message.sender ? message.sender.nickname : '';

  const [selectedQuestion, setSelectedQuestion] = useState(null); // 选中的问题
  const [mode, setMode] = useState('chatbot'); // 当前模式，默认为 'chatbot'

  const faqData = JSON.parse(localStorage.getItem('faqsData'));
  //console.log(faqData);
// 样式
const styles = {
  container: {
    marginLeft: 8,
    maxWidth: '300px',
    padding: '2px',
    position: 'relative',
  },
  buttonGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '10px',
  },
  buttonWrapper: {
    textAlign: 'center',
  },
  button: {
    width: '100%',
    textAlign: 'center',
  },
  activeButton: {
    backgroundColor: '#ff4000',
    color: 'white',
  },
  menu: {
    position: 'absolute',
    top: '60px', // Adjusted to position under buttons
    left: '0',
    width: '100%', // Same width as buttons
    zIndex: 100,
    background: 'white',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  },
  menuItem: {
    padding: '10px',
    borderBottom: '1px solid #f0f0f0',
    color: '#ff4000', // 字体颜色与分类标题相同
    fontSize: '14px', // 字体大小可以根据需要调整
  }
};



  const handleButtonClick = (id) => {
    setActiveButton(id); // 更新激活按钮的状态
  };


  // 处理FAQ点击，发送问题并获取回复
  const handleFAQClick = (id, question) => {
    if (!channel) {
      antdMessage.error("Channel not available.");
      return;
    }
    setActiveButton(id);

    const faqsInfo = ChatActions.viewCount(id);
    if (faqsInfo.status === '200')
    {
      console.log(faqsInfo);
    }
    setSelectedQuestion(question);
    if (mode === 'chatbot') {
      sendMessageAndHandle(question);
    } else {
      antdMessage.info('当前为人工模式，不会自动回复问题。');
    }
  };

  // 切换为人工模式
  const switchToHumanMode = async () => {
    setMode('human');
    //antdMessage.info('已切换到人工模式。');
    const groupInfo = await ChatActions.groupAssign(props.cnuuid);
    if (groupInfo.status === '200')
    {
      console.log(groupInfo);
      sendNotiMessage(groupInfo.data.nickname);
    }
  };

  // 模拟发送消息给 Chatbot 并获取回复
  const sendMessageToChatbot = async (question) => {

      if (!channel) {
        antdMessage.error("Channel not available.");
        return;
      }
      
      try {
        await channel.sendUserMessage({ message: question });

        const matchedFaq = faqData
        .flatMap(category => category.frequently_asked_questions)
        .find(faq => faq.question === question);
        
        // 如果找到匹配的FAQ，获取其answer和id；如果找不到，返回默认值
        if (matchedFaq) {
          const { id, answer, related_questions } = matchedFaq;
          console.log(`Question ID: ${id}, Answer: ${answer}`);
    
          if(related_questions.length > 0){
            related_questions.forEach((rq) => {
    
              console.log(rq.id);
              console.log(rq.question);
              console.log(rq.answer);
            });
    
          }
    
          sendCnBotMessage(answer, id); // 机器人发消息
    
        } else {
          console.log("I don't know the answer to that.");
        }
    


      } catch (error) {
        console.error('Failed to send message:', error);
      }




  };
  // 发送消息函数
  const sendMessage = async (channel, text) => {
    if (!channel) {
      antdMessage.error("Channel not available.");
      return;
    }
    
    try {
      const sentMessage = await channel.sendUserMessage({ message: text });
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };



  // 发送Noti函数
  const sendNotiMessage = (text) => {
    const messageParams = {
      "message": text,
      "channel_url": channel.url,
      "custom_type": "bot_notification"
    };
  
    try {
      const sentMessage = sendBotMessage(messageParams);
      console.log('Message sent by cnchat_bot_1:', sentMessage);
    } catch (error) {
      console.error('Failed to send notification by cnchat_bot_1:', error);
    }
  };

      // 发送消息函数
  const sendCnBotMessage = (text, id) => {
    const messageParams = {
      "message": text,
      "channel_url": channel.url,
      "data": id,
      "custom_type": "bot_auto_reply"
    };
  
    try {
      const sentMessage = sendBotMessage(messageParams);
      console.log('Message sent by cnchat_bot_1:', sentMessage);
    } catch (error) {
      console.error('Failed to send message by cnchat_bot_1:', error);
    }
  };
  // 渲染分类的菜单
  const renderCategoryMenu = (category) => (
    <Menu>
      {category.frequently_asked_questions.map(faq => (
        <Menu.Item key={faq.id} onClick={() => handleFAQClick(faq.id, faq.question)}>
          <span className="faq-question">{faq.question}</span>
        </Menu.Item>
      ))}
    </Menu>
  );
  const channelInvite = async () => {

    const uid = ['9db2151cfea94906aeab673b1163bc33',];
    //const res = await ChannelActions.inviteChannel(channel.url, uid);
    //const res = await ChannelActions.leaveChannel(channel.url, uid);

    const res = await channel.inviteWithUserIds(uid);

    console.log(res);
  };


  async function sendMessageAndHandle(question) {
    try {
        // 发送用户消息
        //const message = await channel.sendUserMessage({ message: question });
        channel.sendUserMessage({
          message: question,
        })
          .onSucceeded((message) => {
            console.log(JSON.stringify(message, null, 2));
          })
          .onFailed((err, message) => {
          // Handle error.
          console.log(err);
          console.log(JSON.stringify(message, null, 2));
          });

          const matchedFaq = faqData
          .flatMap(category => category.frequently_asked_questions)
          .find(faq => faq.question === question);
          
          // 如果找到匹配的FAQ，获取其answer和id；如果找不到，返回默认值
          if (matchedFaq) {
            const { id, answer, related_questions } = matchedFaq;
            console.log(`Question ID: ${id}, Answer: ${answer}`);
      
            if(related_questions.length > 0){
              related_questions.forEach((rq) => {
      
                console.log(rq.id);
                console.log(rq.question);
                console.log(rq.answer);
              });
            }
            setTimeout(() => {
              sendCnBotMessage(answer, id); // 机器人发消息
            }, 1000); // 1000毫秒 = 1秒

        } 
    } catch (err) {
        // 处理错误
        console.error('Error sending message:', err);
    }
}




  return (
    <div className="faq-bot">

<Card sx={ { boxShadow : 'none' } } >


<CardContent>
            <ul className="ufl t10">
              <li><div className="icon2"><Avatar alt="" src={message.sender.plainProfileUrl} /></div></li>
              <li>
                  <strong className=" ">CNINSIDER</strong> <span className="span1">机器人</span>
                  <div div className="t08"></div>
                  <div className="bgd1">{message.message}</div>

                  <div className="t08"></div>
                  <div id="layer_btn1" className="bgd1">
                    请选择分类，查看常见问题
                  <div className="t10"></div>

                              {/* 分类显示 */}
                              <div className="faq-category-list" style={styles.container}>
                              <div style={styles.buttonGrid}>
                                {faqData.map(category => (
                                  <Dropdown
                                    key={category.id}
                                    overlay={renderCategoryMenu(category)}
                                    trigger={['hover']}
                                    placement="bottom"
                                  >

                                    <button
                                      type="button"
                                      className="button1"
                                      style={{
                                        ...styles.button,
                                        ...(activeButton === category.id ? styles.activeButton : {}),
                                      }}
                                      onClick={() => handleButtonClick(category.id)} // 点击时更新状态
                                    >
                                      {category.name}
                                    </button>
                                  </Dropdown>
                                ))}

                                {/* 转为人工模式选项 */}
                                <button type="button" className="button1" style={styles.button}
                                  onClick={() => {
                                    switchToHumanMode();
                                    handleButtonClick('0'); // 为人工模式按钮设置唯一标识
                                  }}
                                >
                                  담당자연결
                                </button>
  
                              </div>
                              </div>
                  </div>



              </li>
            </ul>
</CardContent>


</Card>

      {mode === 'human' && (
        <div className="human-mode-notice">
          <p></p>
        </div>
      )}
    </div>
  );

}


// 从 Redux 中映射 state 到 props
const mapStateToProps = ({
  cnchannelReducer: { current_cnchannel },
  cnuserReducer: { cnuuid }
}) => ({
  current_cnchannel,
  cnuuid
});

export default connect(mapStateToProps)(FAQMessage);

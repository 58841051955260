import React, { useState } from 'react';
import { Card, Avatar, CardContent } from "@mui/material";

import '../pages/user/side/css/productList.css';

const img_url = 'https://gw.alicdn.com/tfs//a7/3f/TB1ww3yLYrpK1RjSZTESuwWAVXa.jpg_320x320q75.jpg_.webp';

const MyOrderMessage = (props) => {
  
	const {
		message,
		customerId,
	  } = props;

  const order = JSON.parse(message.data);


  return (
<div>
      <Card sx={ { boxShadow : 'none' } } >


<CardContent>

{(message.sender.userId === customerId) && (
  
<ul className="ufl t10 fr">
			<li>
				<div className="bgd1_2">


<ul className="ufl" style={{ listStyleType: 'none', padding: 0 }}>
  <li style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>


  <div className="l10">
      
      <div>订单编号 : {order.taskno} </div>
        <div className="t05">
          <span className="red">¥ {order.amount} </span>
          <span className="cont_text4 l15">总数量: {order.quantitynum} </span>
        </div>
      </div>


  </li>
</ul>

<div className="cb"></div>
				</div>
			</li>
			<li><div className="icon2"><Avatar alt="" src={message.sender.plainProfileUrl} /></div></li>
		</ul>

)}

{!(message.sender.userId === customerId) && (

<ul className="ufl t20">

<li><div className="icon2" style={{'margin-left': '8px'}}><Avatar alt="" src={message.sender.plainProfileUrl} /></div></li>
			<li>
				<div className="bgd1_2">


<ul className="ufl" style={{ listStyleType: 'none', padding: 0 }}>
  <li style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

      <div className="l10">
      
      <div>订单编号 : {order.taskno} </div>
        <div className="t05">
          <span className="red">¥ {order.amount} </span>
          <span className="cont_text4 l15">总数量: {order.quantitynum} </span>
        </div>
      </div>

  </li>
</ul>

<div className="cb"></div>
				</div>
			</li>
		</ul>




)}
</CardContent>

</Card>
    </div>


  );
};


export default MyOrderMessage;
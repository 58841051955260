// reducers/sendbirdReducer.js
const initialUserState = {
    cnuuid: null,
    cnchannelurl: null,
};

export const cnuserReducer = (state = initialUserState, action) => {
    switch (action.type) {
        case 'SET_CNUUID':
            return {
                ...state,
                cnuuid: action.payload,
            };
        case 'SET_CNCHANNELURL':
            return {
                ...state,
                cnchannelurl: action.payload,
            };
        default:
            return state;
    }
};

import React from "react";
import { connect } from 'react-redux';
import { Card, Avatar, CardContent } from "@mui/material";
import DOMPurify from 'dompurify';

function UserMessage(props) {
  const {
    message,
    updateUserMessage,
    customerId,
  } = props;
  const nickname = message.sender ? message.sender.nickname : '';
  const userId = props.cnuuid;
  const formattedInput = message.message.replace(/\n/g, '<br>');
  const sanitizedHTML = DOMPurify.sanitize(formattedInput);
  return (

    <div className="user-message">
      <Card sx={ { boxShadow : 'none' } } >


      <CardContent>
          {(message.sender.userId === userId) && (

                  <ul className="ufl t10 fr">
                    <li>
                        {/* <div className="bgd1_2">{message.message}</div> */}
                        <div className="bgd1_2" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    </li>
                    <li><div className="icon2"><Avatar alt="" src={message.sender.plainProfileUrl} /></div></li>
                  </ul>
          )}
          {!(message.sender.userId === userId) && (

                  <ul className="ufl  t20">
                    <li><div className="icon2"><Avatar alt="" src={message.sender.plainProfileUrl} /></div></li>
                    <li>
                        <strong className="cont_text2">{nickname}</strong>
                        <div div className="t08"></div>
                        {/* <div className="bgd1">{message.message}</div> */}
                        <div className="bgd1" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    </li>
                  </ul>
          )}
      </CardContent>


      </Card>
    </div>
  );

}


const mapStateToProps = ({
  cnchannelReducer: { current_cnchannel },
  cnsendbirdReducer: { cnsendbird },
  cnuserReducer: { cnuuid }
}) => ({
  current_cnchannel,
  cnsendbird,
  cnuuid
});

export default connect(mapStateToProps)(UserMessage);
import React, { useState } from 'react';
import { Card, Avatar, CardContent } from "@mui/material";

import '../pages/user/side/css/productList.css';

const img_url = 'https://gw.alicdn.com/tfs//a7/3f/TB1ww3yLYrpK1RjSZTESuwWAVXa.jpg_320x320q75.jpg_.webp';

const ProductMessage = (props) => {
	const {
		message,
		customerId,
	  } = props;
  const product = JSON.parse(message.data);
  return (
<div>
      <Card sx={ { boxShadow : 'none' } } >


<CardContent>
<ul className="ufl t10 fr">
			<li>
				<div className="bgd1_2">


<ul className="ufl" style={{ listStyleType: 'none', padding: 0 }}>
  <li style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
    <a href={product.prd_link} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', textDecoration: 'none', color: 'inherit' }}>
    <li><div className="img1"><img src={img_url} alt={product.prd_name} style={{ width: '80px', height: '80px', objectFit: 'cover' }} /></div></li>
      <div className="l10" style={{ marginLeft: '10px', width: 'calc(100% - 50px)' }}>
        <p style={{ margin: 0 }}>{product.prd_name}</p>
        <div className="t05">
          <span className="red r20">¥ {product.prd_price}</span>
          <span className="cont_text4">库存: {product.prd_stock}</span>
        </div>
      </div>
    </a>
  </li>
</ul>

<div className="cb"></div>
				</div>
			</li>
			<li><div className="icon2"><Avatar alt="" src={message.sender.plainProfileUrl} /></div></li>
		</ul>
{/* 
<ul className="ufl t10 fr">
			<li>
				<div className="bgd1_2">
					<ul className="ufl">
						<li><div className="img1"><img src={img_url} alt={product.prd_name} style={{ width: '80px', height: '80px', objectFit: 'cover' }} /></div></li>
						<li>
							新款钱包女韩版简约迷你格子钱包高中生复古…
								
							<div className="cont_text3 t05">
								CN121902T2644810
							</div>
              <span className="red">¥ {product.prd_price.toFixed(2)}</span>
              <span className="cont_text4">库存: {product.prd_stock}</span>
						</li>
					</ul>
					<div className="t08 cb"><div className="line2 container-fluid t08"></div></div>
					<ul>
						<li className="l08">南昌市西湖区娜和贸易商行
							(个体工商户)</li>
					</ul>
					<div className="cb"></div>
				</div>
			</li>
		</ul>
 */}














</CardContent>

</Card>
    </div>


  );
};


export default ProductMessage;
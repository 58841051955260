import { legacy_createStore, combineReducers } from 'redux';
import { cnchannelReducer } from './reducers/ChannelReducer';
import { cnsendbirdReducer } from './reducers/SendbirdReducer';
import { cnuserReducer } from './reducers/UserReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
//引入redux可视化谷歌插件
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
    key: 'egchat_reducers',
    storage,
    whitelist: [
        'cnchannelReducer'
    ]
}

const reducer = combineReducers({
    cnchannelReducer,
    cnsendbirdReducer,
    cnuserReducer
});

//reducer进行持久化处理
const persistedReducer = persistReducer(persistConfig, reducer)

const cnstore = legacy_createStore(persistedReducer,composeWithDevTools());
const cnpersistor = persistStore(cnstore);

export { cnstore, cnpersistor };
import React, { useState, useEffect, useRef } from 'react';
import ConsultingProducts from './ConsultingProducts';
import OtherProducts from './OtherProducts';
import { ChatActions } from "../../../actions/common/ChatActions";

function MyProductLib({ tenantId, userId }) {
  const [options, setOptions] = useState({
    page: 1,
    pageSize: 20,
    tenantId,
    customerId: userId,
  });

  const [otherOptions, setOtherOptions] = useState({
    page: 1,
    pageSize: 20,
    tenantId,
    customerId: userId,
    searchContent: '',
    ptype: '',
    labelType: '',
    waterTagType: '',
  });

  const [selectedCategory, setSelectedCategory] = useState('consulting');
  const [results, setResults] = useState([]);
  const [otherResults, setOtherResults] = useState([]);
  const [totalNum, setTotalNum] = useState(0);
  const [loading, setLoading] = useState(false);
  const isFirstRender = useRef(true);
  const resultsContainerRef = useRef(null); // 引用结果容器

  const fetchProducts = async (options) => {
    if (loading) return;
    setLoading(true);
    try {

      if(options.page === 1)
      {
        setResults([]);
        setOtherResults([]);
      }

      const res = await ChatActions.getProductInfo(options);
      if (res && res.data && Array.isArray(res.data.items)) {
        return res.data;
      }
    } catch (error) {
      console.error(`数据加载失败: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const loadData = async () => {
    if (selectedCategory === 'consulting') {
      const data = await fetchProducts(options);
      if (data) {
        setResults((prevResults) => [...prevResults, ...data.items]);
        setTotalNum(data.total);
      }
    } else if (selectedCategory === 'other') {
      const data = await fetchProducts(otherOptions);
      if (data) {
        setOtherResults((prevResults) => [...prevResults, ...data.items]);
        setTotalNum(data.total);
      }
    }
    scrollToBottom(); // 数据加载后滚动到最底部
  };

  const scrollToBottom = () => {
    const container = resultsContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
/* 
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    */ 
    loadData();
  }, [options, otherOptions, selectedCategory]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setResults([]); // 清空咨询商品结果
    setOtherResults([]); // 清空其他商品结果
    if (category === 'consulting') {
      setOptions((prev) => ({ ...prev, page: 1 })); // 重置咨询商品选项
    } else {
      setOtherOptions((prev) => ({ ...prev, page: 1, searchContent: '', ptype: '', labelType: '', waterTagType: '' })); // 重置其他商品选项
    }
  };

  const handleScroll = () => {
    const container = resultsContainerRef.current;
    if (container && !loading) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        // 只有在有更多结果的情况下才请求新数据
        if (selectedCategory === 'consulting' && results.length < totalNum) {
          setOptions((prev) => ({ ...prev, page: prev.page + 1 }));
        } else if (selectedCategory === 'other' && otherResults.length < totalNum) {
          setOtherOptions((prev) => ({ ...prev, page: prev.page + 1 }));
        }
      }
    }
  };

  useEffect(() => {
    const container = resultsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [loading, selectedCategory, results, otherResults]);

  return (
    <div className="scrollable-content">
      <div className="t10 b10 l30">
        <button
          className={selectedCategory === 'consulting' ? 'button5_cur active' : 'button5'}
          type="button"
          onClick={() => handleCategoryChange('consulting')}
        >
          正在咨询的商品
        </button>
        <button
          className={selectedCategory === 'other' ? 'button5_cur active' : 'button5'}
          type="button"
          onClick={() => handleCategoryChange('other')}
        >
          其他商品
        </button>
      </div>

      <div ref={resultsContainerRef} style={{ maxHeight: '79vh', overflowY: 'auto' }}>
        {selectedCategory === 'consulting' && (
          <ConsultingProducts
            options={options}
            setOptions={setOptions}
            loading={loading}
            results={results}
            totalNum={totalNum}
          />
        )}

        {selectedCategory === 'other' && (
          <OtherProducts
            otherOptions={otherOptions}
            setOtherOptions={setOtherOptions}
            fetchOtherProducts={fetchProducts}
            loading={loading}
            otherResults={otherResults}
            totalNum={totalNum}
          />
        )}
      </div>
    </div>
  );
}

export default MyProductLib;

import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { ChatActions } from "../../../actions/common/ChatActions";
import './css/productList.css';
import MySKU from './MySKU';

// 产品项组件
const ProductItem = React.memo(({ product, handleSendProduct, handleChooseSKU }) => (
  <div className="bgd1_3">
    <ul className="ufl l30" style={{ listStyle: 'none', padding: 10, margin: 0 }}>
      <li style={{ position: 'relative', display: 'flex', alignItems: 'flex-start' }}>
        <a href={product.productImage} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
          <div className="img1" style={{ width: '60px', height: '60px', overflow: 'hidden' }}>
            <img
              src={product.productInfo.image}
              alt={product.productInfo.title}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
        </a>
        <div className="l10" style={{ width: 'calc(100% - 70px)' }}>
          <div>{product.title}</div>
          <div className="t05">
            <span className="red">{product.productInfo.referencePrice} {product.productInfo.priceUnit}</span>
            <span className="cont_text4 l15">库存: {product.productInfo.canBookCount} {product.productInfo.unit}</span>
          </div>
          <div className="cb t15">
            <button className="button6" type="button" onClick={() => handleSendProduct(product)}>发送商品</button>
            {
              product.productInfo.skus &&
            <button className="button6" type="button" onClick={() => handleChooseSKU(product.skus)}>发送SKU</button>
            }
          </div>
        </div>
      </li>
    </ul>
    <div className="cb"></div>
  </div>
));

const ProductList = (props) => {
  const { data, cnuuid, cnchannelurl } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [skus, setSkus] = useState([]);

  const handleChooseSKU = useCallback((skus) => {
    setSkus(skus);
    setIsModalOpen(true);
  }, []);

  const handleSelectSku = useCallback((sku) => {
    setSelectedSkus((prevSelected) =>
      prevSelected.includes(sku) ? prevSelected.filter((s) => s !== sku) : [...prevSelected, sku]
    );
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedSkus([]);
  }, []);

  const handleSendProduct = async (product) => {
    const params = {
      message: '',
      message_type: 'MESG',
      user_id: cnuuid,
      custom_type: 'utis',
      data: JSON.stringify(product)
    };
    await ChatActions._sendMsg(cnchannelurl, params);
  };

  const handleSendSKU = async (sku) => {
    const params = {
      message: '',
      message_type: 'MESG',
      user_id: cnuuid,
      custom_type: 'sku',
      data: JSON.stringify(sku)
    };
    await ChatActions._sendMsg(cnchannelurl, params);
    closeModal();
  };

  return (
    <div>
      {data && data.map(product => (
        <ProductItem 
          key={product.id} // 假设每个产品有唯一的 id
          product={product} 
          handleSendProduct={handleSendProduct}
          handleChooseSKU={handleChooseSKU}
        />
      ))}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <MySKU 
              skus={skus} 
              closeModal={closeModal} 
              cnuuid={cnuuid} 
              cnchannelurl={cnchannelurl} 
              onSelectSku={handleSelectSku} // 如果需要处理 SKU 选择
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ cnuserReducer: { cnuuid, cnchannelurl } }) => ({
  cnuuid,
  cnchannelurl
});

export default connect(mapStateToProps)(ProductList);

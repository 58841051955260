import React from "react";
import './index.css'
import { Card, CardContent, Typography, Avatar } from "@mui/material";

export default function AdminMessage(props) {
  const { message } = props;
  return (
    <div className="admin-message">
      <Card>
        <CardContent>
          <Avatar alt="Us" src="https://1688chatadmin.ggook.com/avatar/avatar_c.png" />
          <Typography color="textSecondary">Admin message</Typography>
          <Typography variant="body2" component="p">
            {message.message}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
  // 工具按钮组件
  export const ToolButton = ({ src, alt, onClick }) => (
    <button className="tool-button" onClick={onClick}>
      <img src={`/img/chat/${src}.png`} alt={alt} className="tool-icon" />
    </button>
  );
  
//允许上传的文件（用于获取文件图标）
export const allowedFiles = {
  'plain': "/img/chat/txt.png",
  'doc': "/img/chat/word.png",
  'docx': "/img/chat/word.png",
  'xls': "/img/chat/excel.png",
  'xlsx': "/img/chat/excel.png",
  'ppt': "/img/chat/ppt.png",
  'pptx': "/img/chat/ppt.png",
  'pdf': "/img/chat/pdf.png",
  'zip': "/img/chat/zip.png",
  'rar': "/img/chat/zip.png"
};
  // 文件类型与图标映射
  export const fileTypeIcons = {
    'text/plain': "/img/chat/txt.png",
    'application/msword': "/img/chat/word.png",
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': "/img/chat/word.png",
    'application/vnd.ms-excel': "/img/chat/excel.png",
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': "/img/chat/excel.png",
    'application/vnd.ms-powerpoint': "/img/chat/ppt.png",
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': "/img/chat/ppt.png",
    'application/pdf': "/img/chat/pdf.png",
    'application/zip': "/img/chat/zip.png",
    'application/x-rar-compressed': "/img/chat/zip.png"
  };
  //允许上传的视频（用于获取文件图标）
export const allowedVideo = ['mov', 'quicktime', 'avi', 'mp4'];

//允许上传的mime类型
export const allowedMIME = [
    'text/plain',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
    'image/webp',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf',
    'video/quicktime',
    'video/avi',
    'video/mp4',
    'application/x-zip-compressed',
    'application/zip',
    'application/x-rar-compressed',
    'application/rar',
];

//表情图片
export const EMOJI = {
    symbol: '#^.^',
    symbolReg: '#\\^\\.\\^',
    link: (key) => {
        const emoji = key.replace(EMOJI.symbol, '');
        return '<img src="https://emojikeyboard.io/img/img-apple-64/1f'+emoji+'.png" class="emoji" alt="" />';
    }
};  
  export const setEndOfContenteditable = (contentEditableElement) => {
    var range, selection;
    if (document.createRange) {
        range = document.createRange();//Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection();//get the selection object (allows you to change selection)
        selection.removeAllRanges();//remove any selections already made
        selection.addRange(range);//make the range you have just created the visible selection
    } else if (document.selection) {
        range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
        range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        range.select();//Select the range (make it the visible selection
    }
}
    
  // 表情列表
  export const EmojiList = [
    { id: 1, emoji: '😀' },
    { id: 2, emoji: '😃' },
    { id: 3, emoji: '🤞' },
    { id: 4, emoji: '😁' },
    { id: 5, emoji: '😆' },
  ];


import React, { useState } from 'react';
import { Card, Avatar, CardContent } from "@mui/material";

import '../pages/user/side/css/productList.css';

const img_url = 'https://gw.alicdn.com/tfs//a7/3f/TB1ww3yLYrpK1RjSZTESuwWAVXa.jpg_320x320q75.jpg_.webp';

function formatTimestamp(timestamp) {
	const date = new Date(timestamp);
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	return `${hours}:${minutes}`; // 返回时:分格式
  }

const HisProductMessage = (props) => {
  const message = props.message;
  const nickname = message.sender ? message.sender.nickname : '';
  console.log(message);
  const product = JSON.parse(message.data);
  const formattedTime = formatTimestamp(message.createdAt);


  return (
<div>
      <Card sx={ { boxShadow : 'none' } } >


<CardContent>

		<div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            size={37}
            alt=""
            src={message.sender.plainProfileUrl}
            style={{ marginRight: '8px' }} // 添加右边距以分隔头像和文本
          />
          <div>
            <strong className="cont_text2">{nickname}</strong>
            <span className="cont_text1" style={{ marginLeft: '8px' }}>{formattedTime}</span>
          </div>
        </div>

	

		<ul className="ufl t10 fr">
			<li>
				<div className="bgd1_2">


						<ul className="ufl" style={{ listStyleType: 'none', padding: 0 }}>
							<li style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
							<a href={product.prd_link} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', textDecoration: 'none', color: 'inherit' }}>
							<li><div className="img1"><img src={img_url} alt={product.prd_name} style={{ width: '80px', height: '80px', objectFit: 'cover' }} /></div></li>
							<div className="l10" style={{ marginLeft: '10px', width: 'calc(100% - 50px)' }}>
								<p style={{ margin: 0 }}>{product.prd_name}</p>
								<div className="t05">
								<span className="red r20">¥ {product.prd_price.toFixed(2)}</span>
								<span className="cont_text4">库存: {product.prd_stock}</span>
								</div>
							</div>
							</a>
							</li>
						</ul>

						<div className="cb"></div>
				</div>
			</li>
		</ul>















</CardContent>

</Card>
    </div>


  );
};


export default HisProductMessage;
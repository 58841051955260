import React, { useState, useEffect, useRef } from 'react';
import { DatePicker, Button, Spin } from 'antd';
import MyOrderList from './MyOrderList';

const { RangePicker } = DatePicker;

function OtherOrders({ otherOptions, setOtherOptions, fetchOtherOrders, loading, otherResults, totalNum }) {
  const [orderType, setOrderType] = useState('all');
  const resultsContainerRef = useRef(null);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const formattedDates = [
        dates[0].format('YYYY-MM-DD HH:mm:ss'),
        dates[1].format('YYYY-MM-DD HH:mm:ss'),
      ];
      setOtherOptions((prev) => ({
        ...prev,
        order_time: formattedDates,
        page: 1, // Reset to first page when date changes
      }));
    } else {
      setOtherOptions((prev) => ({
        ...prev,
        order_time: [],
        page: 1, // Reset to first page if no date selected
      }));
    }
  };

  // 滚动加载下一页
  const handleScroll = () => {
    const container = resultsContainerRef.current;
    if (container && !loading) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 5 && otherResults.length < totalNum) {
        setOtherOptions((prev) => ({
          ...prev,
          page: prev.page + 1,
        }));
      }
    }
  };

  useEffect(() => {
    const container = resultsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [loading]);

  // 处理搜索按钮点击
  const handleSearch = () => {
    setOtherOptions((prev) => ({
      ...prev,
      page: 1, // 重置页码
    }));
    
    // 调用查询函数
    fetchOtherOrders(otherOptions);
  };

  const handleOrderTypeChange = (e) => {
    const value = e.target.value;
    setOrderType(value);
    setOtherOptions((prev) => {
      const newOptions = { ...prev };

      if (value === "purchase" || value === "shipment") {
        newOptions.order_type = value;
      } else {
        delete newOptions.order_type;
      }

      return {
        ...newOptions,
        page: 1,
      };
    });
  };

  return (
    <div ref={resultsContainerRef} style={{ maxHeight: '79vh', overflowY: 'auto' }}>
      <div className="bgd1_3">
        <table className="table_01">
          <caption>搜索表格</caption>
          <tbody>
            <tr>
              <td style={{ width: '65px' }}>综合搜索</td>
              <td className="l10">
                <input
                  type="text"
                  name="text"
                  className="input1"
                  placeholder="输入商品ID/名称/链接/..."
                  value={otherOptions.comprehensive}
                  onChange={(e) => setOtherOptions((prev) => ({ ...prev, comprehensive: e.target.value }))}
                />
              </td>
            </tr>
            <tr>
              <td>订单类型</td>
              <td className="l10">
                <select
                  name="select"
                  className="select1"
                  value={orderType}
                  onChange={handleOrderTypeChange}
                >
                  <option value="all">全部</option>
                  <option value="purchase">采购单</option>
                  <option value="shipment">发货单</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>创建时间</td>
              <td className="l10">
                <RangePicker
                  showTime
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="ac t10">
        <button
          className="button2"
          type="button"
          style={{ height: '30px', width: '60px' }}
          onClick={handleSearch} // 点击搜索按钮触发查询
        >
          搜索
        </button>        
        <div className="t10"></div>
      </div>

      {otherResults.length > 0 ? (
        <MyOrderList data={otherResults} />
      ) : (
        <div style={{ textAlign: 'center' }}>...</div>
      )}
      {loading && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
}

export default OtherOrders;

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { cnstore, cnpersistor } from '../redux/store';
import Header from "./Header";
import ChatApp from "./ChatApp";
import CnSearch from "../side/CnSearch";
import HistoryView from "../side/HistoryView";
import Loading from "../../../components/loading";
import "./style.css";

const Main = () => {
  const [loading, setLoading] = useState(false);
  const { tenantId, userId } = useParams();
  const [sharedData, setSharedData] = useState('');

  const updateSharedData = (newData) => {
    setSharedData(newData);
    localStorage.setItem('sharedData', newData);
  };

  useEffect(() => {
    cnstore.dispatch({ type: 'SET_CNUUID', payload: userId });    
}, [userId]);

  return (
    <Provider store={cnstore}>
      <PersistGate loading={null} persistor={cnpersistor}>
        <main>
          <div className="frame">
            <div className="box header"><Header sharedData={sharedData} /></div>
            <div className="box mainarea"><ChatApp tenantId={tenantId} userId={userId} updateSharedData={updateSharedData} /></div>
            <div className="box servarea"><CnSearch tenantId={tenantId} userId={userId} /></div>
            {/* <div className="box servarea"><Loading loading={loading ? 'block' : 'none'} /></div> */}
            
          </div>
        </main>
      </PersistGate>
    </Provider>
  );
};

export default Main;

import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import RouterUser from './RouterUser';

function Router(props) {

    return (
        <HashRouter>
            <Routes>
                <Route path="/user/*" element={<RouterUser />} />
            </Routes>
        </HashRouter>
    );
}

export default Router;
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import HisMessageItem from "../home/HisMessageItem";
import { Input, message } from 'antd';

const MessageSearch = (props) => {
    const [messages, setMessages] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [nextToken, setNextToken] = useState(null);
    const [collection, setCollection] = useState(null);
    
    const containerRef = useRef();
    const observerRef = useRef();

    // 获取消息
    const fetchMessages = async () => {
        if (!hasMore || loading) return;
        setLoading(true);

        try {
            if (collection) {
                collection.dispose(); // 清理旧的集合
            }

            const channel = props.current_cnchannel;
            const messageCollection = channel.createMessageCollection({
                filter: props.filter,
                limit: 100,
                nextToken: nextToken,
            });

            // 设置消息集合处理程序
            messageCollection.setMessageCollectionHandler({
                onMessagesAdded: (context, channel, addedMessages) => {
                    setMessages(prevMessages => [...prevMessages, ...addedMessages]);
                },
                onMessagesUpdated: (context, channel, updatedMessages) => {
                    setMessages(prevMessages => prevMessages.map(msg =>
                        updatedMessages.find(uMsg => uMsg.messageId === msg.messageId) || msg
                    ));
                },
                onMessagesDeleted: (context, channel, deletedMessageIds) => {
                    setMessages(prevMessages => prevMessages.filter(msg => !deletedMessageIds.includes(msg.messageId)));
                },
            });

            // 加载初始消息
            const loadedMessages = await messageCollection.loadPrevious();
            setMessages(prevMessages => [...prevMessages, ...loadedMessages]);
            setNextToken(messageCollection.nextToken);
            setHasMore(loadedMessages.length === 100); // 如果加载的消息数量等于100，表示还有更多消息

            setCollection(messageCollection);
        } catch (error) {
            message.error('获取历史消息失败');
            console.error('Error fetching messages:', error);
        } finally {
            setLoading(false);
        }
    };

    // 滚动加载更多消息
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore && !loading) {
                    fetchMessages();
                }
            },
            { root: containerRef.current, threshold: 1.0 }
        );

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => observer.disconnect();
    }, [hasMore, loading]);

    // 关键词过滤消息
    useEffect(() => {
        const filterMessages = () => {
            if (keyword) {
                setFilteredMessages(messages.filter(message => {
                    const isTextMatch = message.message?.toLowerCase().includes(keyword.toLowerCase());
                    const isFileMatch = message.type === 'file' && message.name?.toLowerCase().includes(keyword.toLowerCase());
                    const isImageMatch = message.type === 'image' && message.url?.toLowerCase().includes(keyword.toLowerCase());
                    return isTextMatch || isFileMatch || isImageMatch;
                }));
            } else {
                setFilteredMessages(messages);
            }
        };

        filterMessages();
    }, [keyword, messages]);

    useEffect(() => {
        if(messages.length > 0){

                if(props.type === 'DOC')
                {
                    setFilteredMessages(messages.filter(message => {
                        return message.type?.toLowerCase().includes('application');
                    }));
                }
                else if(props.type === 'IMG'){
                    setFilteredMessages(messages.filter(message => {
                        return message.type?.toLowerCase().includes('image');
                    }));
                }
                else if(props.type === 'PRODUCT'){
                    const keywords = ['myproduct', 'sku', 'utis'];
                    setFilteredMessages(messages.filter(message => {
                        return keywords.some(keyword =>
                          message.customType?.toLowerCase().includes(keyword)
                        );
                      }));
                }
                else if(props.type === 'ORDER'){
                    setFilteredMessages(messages.filter(message => {
                        return message.customType?.toLowerCase().includes('myorder');
                    }));
                }
                else
                {
                    console.log(props.type);
                    setFilteredMessages(messages);
                }
        }


    }, [props.type, messages]);

    return (
        <div style={{ padding: '20px', height: '500px', overflowY: 'auto' }} ref={containerRef}>
            
            { (props.type === 'ALL') &&
            <Input
                placeholder="搜索"
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                style={{ marginBottom: '10px' }}
                className="input1"
            />
            }


            <div className="ac t20">
                {filteredMessages.length === 0 ? (
                    <>
                        <img src="/img/bu7.png" alt="无记录" />
                        <div className="cont_text3 t05">暂无记录</div>
                    </>
                ) : (
                    <div>
                        {filteredMessages.map((message) => (
                            <HisMessageItem key={message.messageId} message={message} />
                        ))}
                    </div>
                )}
            </div>
            {/* 滚动加载的触发点 */}
            <div ref={observerRef} style={{ height: '1px' }}></div>
            {loading && <div>加载中...</div>}
        </div>
    );
};

const mapStateToProps = ({
    cnchannelReducer: { current_cnchannel }
  }) => ({
    current_cnchannel
  });


export default connect(mapStateToProps)(MessageSearch);
import React, { useMemo, useEffect, useRef } from 'react';
import AdminMessage from "../../../MessageItems/AdminMessage";
import FileMessage from "../../../MessageItems/FileMessage";
import UserMessage from "../../../MessageItems/UserMessage";
//import UtisMsg from "../../../MessageItems/UtisMsg";
import ProductMessage from "../../../MessageItems/ProductMessage";
import MyProductMessage from "../../../MessageItems/MyProductMessage";
import SkuMessage from "../../../MessageItems/SkuMessage";
import MyOrderMessage from "../../../MessageItems/MyOrderMessage";
import FAQMessage from "../../../MessageItems/FAQMessage";
import BotMessage from "../../../MessageItems/BotMessage";
import NotiMessage from "../../../MessageItems/NotiMessage";
import "./customizedMessageItem.css";
// 自定义时间线组件
const Timeline = ({ date }) => {
  return (
    <div className="timeline">
      <span> {date} </span>
    </div>
  );
};

// 格式化日期函数
const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();

  // 判断是否是今天
  if (date.toDateString() === now.toDateString()) {
    // 返回 24 小时制的时:分 格式
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  } else {
    // 返回完整的日期格式
    return date.toLocaleDateString(); // 根据需求自定义日期格式
  }
};
let lastMessageTime = null;

function CustomizedMessageItem({ userId, message, updateUserMessage }) {

  const MessageHOC = useMemo(() => {

      const currentMessageTime = new Date(message.createdAt);
      // 检查是否超过5分钟
      const timeDiff = lastMessageTime 
        ? (currentMessageTime - lastMessageTime) / 1000 / 60 // 转换为分钟
        : 6; // 初始情况下设为大于5分钟

      const showTimeline = timeDiff >= 5;
      // 更新最后消息时间
      lastMessageTime = currentMessageTime;

      return (
        <>
          {showTimeline && <Timeline date={formatDate(message.createdAt)} />}
          {message.isAdminMessage?.() && <AdminMessage message={message} />}
          {message.isFileMessage?.() && (
            <FileMessage message={message} />
          )}
          {message.isUserMessage?.() && (message.customType === "faq_auto_reply") &&(
            <FAQMessage message={message} />
          )}
          {message.isUserMessage?.() && (message.customType === "bot_auto_reply") &&(
            <BotMessage message={message} />
          )}
          {message.isUserMessage?.() && (message.customType === "bot_notification") &&(
            <NotiMessage message={message} />
          )}

          {message.isUserMessage?.() && (message.customType === "myproduct") &&(
            <MyProductMessage message={message} customerId={userId} />
          )}

          {message.isUserMessage?.() && (message.customType === "myorder") &&(
            <MyOrderMessage message={message} customerId={userId} />
          )}
          {message.isUserMessage?.() && (message.customType === "utis") &&(
            <ProductMessage message={message} customerId={userId} />
          )}
          
          {message.isUserMessage?.() && (message.customType === "sku") &&(
            <SkuMessage message={message} customerId={userId} />
          )}

          {message.isUserMessage?.() && (message.customType === "") &&(
            <UserMessage message={message} updateUserMessage={updateUserMessage} customerId={userId} />
          )}
          
        </>
      );

      //return null;
  }, [message, updateUserMessage]);

  // 引用容器元素以进行滚动
  const messageContainerRef = useRef(null);
  // 自动滚动到最新消息
  useEffect(() => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight; // 滚动到最底部
    }
  }, [message]); // 依赖于消息的变化

  return (
    <div id={message.messageId} className="customized-message-item">
      {MessageHOC}
    </div>
  );
}

export default CustomizedMessageItem;

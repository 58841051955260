import React, { useState, useEffect } from 'react';

// 将服务时间对象转为文本
function convertServiceTimesToText(serviceTimes) {
  console.log(serviceTimes);
  return serviceTimes.map((time, index) => {
    const startWeek = weekNumberToText(time.startWeek);
    const endWeek = weekNumberToText(time.endWeek);
    return `${startWeek}至${endWeek} ${time.startHour}-${time.endHour}`;
  }).join(', ');
}

// 将节假日对象转为文本
function convertHolidaysToText(holidays) {
  return holidays.map(holiday => {
    return `${holiday.name}休息 ${formatDate(holiday.startDate)}~${formatDate(holiday.endDate)}`;
  }).join('\n');
}

// 将数字周转为文本
function weekNumberToText(weekNumber) {
  const weekMap = {
    1: '周一',
    2: '周二',
    3: '周三',
    4: '周四',
    5: '周五',
    6: '周六',
    7: '周日'
  };
  return weekMap[weekNumber] || '未知';
}

// 日期格式化函数
function formatDate(dateString) {
  const date = new Date(dateString);
  //const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${month}-${day}`;
}

// 转换为文本并输出
function convertToText(serviceTimes, holidays) {
  const serviceTimesText = convertServiceTimesToText(serviceTimes);
  const holidaysText = convertHolidaysToText(holidays);
  return `服务时间: ${serviceTimesText}, ${holidaysText}`;
}

const Header = (sharedData) => {

  const [showInfo, setShowInfo] = useState('');
  console.log(sharedData);
  console.log(sharedData.sharedData.service_time);
  
  // 初始化时处理来自父组件的截图文件
  useEffect(() => {
      try {
        if(sharedData.sharedData)
          {
            // 服务时间对象
            const serviceTimes = sharedData.sharedData.service_time;
            // 节假日对象
            const holidays = sharedData.sharedData.holiday_setting;
            // 调用函数
            const result = convertToText(serviceTimes, holidays);
            //console.log(result);
            setShowInfo(result);
          }
      } catch (error) {
        console.error(`Sendbird 初始化失败: ${error.message}`);
      }
  }, [sharedData.sharedData]);

  // 状态管理：控制模态框是否显示
  const [showModal, setShowModal] = useState(false);

  // 打开模态框
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // 关闭模态框
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // 确认关闭页面
  const handleConfirmClose = () => {
    window.close(); // 尝试关闭页面
  };
  
  return (
    <header className="header_div1">
      <ul className="ufl">
        <li>
          <div className="icon1">
            <img src="img/icon1.png" alt="Icon" />
          </div>
        </li>
        <li className="t02">
          CNINSIDER
          <div className="h_text1">
          {showInfo}
          </div>
        </li>
      </ul>
{/* 
      <a href="#" className="fr">
        <img src="img/exit.png" alt="Exit" />
      </a>
       */}
      <div className="cb" />
    </header>
  );
};

export default Header;

import React, { useEffect, useState, useCallback } from "react";
import { Tabs, Modal } from 'antd';
import './chatWindow.css';
import MessageSearch from './MessageSearch';

function ChatWindow({ visible, onClose }) {
    const [filter, setFilter] = useState({ messageTypeFilter: 'ALL' });
    const [selectedType, setSelectedType] = useState('ALL');
    const [selectedFilter, setSelectedFilter] = useState({ messageTypeFilter: '' });

    const handleFilterChange = (type) => {

        setFilter((prevFilter) => {
          if (prevFilter.messageTypeFilter === type) {
            return prevFilter; 
          }

          if(type === 'ALL')
          {
            setSelectedFilter({messageTypeFilter: ''});
          }
          else if(type === 'DOC' || type === 'IMG')
          {
            setSelectedFilter({messageTypeFilter: 'FILE'});
          }
          else{
            setSelectedFilter({messageTypeFilter: 'USER'});
          }

          setSelectedType(type);
          return { messageTypeFilter: type };
        });        
    };

    return (

        <Modal
        title={
          <div style={{ textAlign: 'center', width: '100%', fontSize: '14px' }}>
            查询历史记录
          </div>
        }
        className="custom-modal"
        open={visible}
        onCancel={onClose}
        closable={false}
        footer={null}
        mask={false} // 禁用遮罩层
        width={400}
        height={529}
        style={{ maxHeight: '84vh', minHeight: '83vh', position: 'fixed', top: '74px', right: '0px' }} // 设置弹窗距页面顶部的距离
      >
      <div style={{ maxHeight: '84vh', minHeight: '83vh', overflowY: 'auto' }}>
        <div className="scrollable-container">
            {/* Header start */}

            <div className="tab_btn1 line1">
            <ul className="ufl">
                <li className={selectedType === 'ALL' ? 'cur' : ''}>
                    <button 
                    onClick={() => handleFilterChange('ALL')} 
                    className={`filter-button ${selectedType === 'ALL' ? 'active' : ''}`}
                    >
                    全部
                    </button>
                </li>
                <li className={selectedType === 'PRODUCT' ? 'cur l05' : 'l05'}>
                    <button 
                    onClick={() => handleFilterChange('PRODUCT')} 
                    className={`filter-button ${selectedType === 'PRODUCT' ? 'active' : ''}`}
                    >
                    商品
                    </button>
                </li>
                <li className={selectedType === 'ORDER' ? 'cur l05' : 'l05'}>
                    <button 
                    onClick={() => handleFilterChange('ORDER')} 
                    className={`filter-button ${selectedType === 'ORDER' ? 'active' : ''}`}
                    >
                    订单
                    </button>
                </li>
                <li className={selectedType === 'IMG' ? 'cur l05' : 'l05'}>
                    <button 
                    onClick={() => handleFilterChange('IMG')} 
                    className={`filter-button ${selectedType === 'IMG' ? 'active' : ''}`}
                    >
                    图片
                    </button>
                </li>
                <li className={selectedType === 'DOC' ? 'cur l05' : 'l05'}>
                    <button 
                    onClick={() => handleFilterChange('DOC')} 
                    className={`filter-button ${selectedType === 'DOC' ? 'active' : ''}`}
                    >
                    文件
                    </button>
                </li>
            </ul>
            </div>

            <MessageSearch filter={selectedFilter} type={selectedType} />
        </div>
      </div>
      </Modal>

    );
};

export default ChatWindow;

import React, { useState } from 'react';
import MyRecentViews from './MyRecentViews';
import MyProductLib from './MyProductLib';
import MyOrders from './MyOrders';
import './cnSearch.css';

function CnSearch({ tenantId, userId }) {
  const [selectedTab, setSelectedTab] = useState('RECVIEWED');

  const handleTabChange = (type) => {
    setSelectedTab(type);
  };
  const renderContent = () => {
    switch (selectedTab) {
      case 'RECVIEWED':
        return <MyRecentViews tenantId={tenantId} userId={userId} />;
      case 'PRODUCT':
        return <MyProductLib tenantId={tenantId} userId={userId} />;
      case 'ORDER':
        return <MyOrders tenantId={tenantId} userId={userId} />;
      default:
        return <div>Loading...</div>;
    }
  };

  return (    
    <div className="scrollable-container">
      {/* 顶部导航 */}
      <div className="tab_btn1">
        <ul className="ufl l30">

          <li className={selectedTab === 'RECVIEWED' ? 'cur' : ''}>
              <button 
              onClick={() => handleTabChange('RECVIEWED')} 
              className={`tab-button ${selectedTab === 'RECVIEWED' ? 'active' : ''}`}
              >
              最近浏览
              </button>
          </li>
          <li className={selectedTab === 'PRODUCT' ? 'cur l05' : 'l05'}>
              <button 
              onClick={() => handleTabChange('PRODUCT')} 
              className={`tab-button ${selectedTab === 'PRODUCT' ? 'active' : ''}`}
              >
              我的商品库
              </button>
          </li>
          <li className={selectedTab === 'ORDER' ? 'cur l05' : 'l05'}>
              <button 
              onClick={() => handleTabChange('ORDER')} 
              className={`tab-button ${selectedTab === 'ORDER' ? 'active' : ''}`}
              >
              我的订单
              </button>
          </li>

        </ul>
      </div>
      {/* 内容区域 */}
      <div className="content-area">
        {renderContent()}
      </div>

      <div className="t10"></div>
    </div>
  );
};

export default CnSearch;

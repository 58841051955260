import React from "react";
import { connect } from 'react-redux';
import { Card, Avatar, CardContent } from "@mui/material";
import DOMPurify from 'dompurify';

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`; // 返回时:分格式
}

function HisUserMessage(props) {
  const message = props.message;
  const formattedInput = message.message.replace(/\n/g, '<br>');
  const sanitizedHTML = DOMPurify.sanitize(formattedInput);
  const nickname = message.sender ? message.sender.nickname : '';
  const formattedTime = formatTimestamp(message.createdAt);
  return (

        <div className="user-message">
          <Card sx={ { boxShadow : 'none', textAlign: 'left' } } >

          <CardContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            size={37}
            alt=""
            src={message.sender.plainProfileUrl}
            style={{ marginRight: '8px' }} // 添加右边距以分隔头像和文本
          />
          <div>
            <strong className="cont_text2">{nickname}</strong>
            <span className="cont_text1" style={{ marginLeft: '8px' }}>{formattedTime}</span>
          </div>
        </div>

        <div className="cont_text2 t05" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />

      </CardContent>

      </Card>
    </div>
  );

}


const mapStateToProps = ({
  cnchannelReducer: { current_cnchannel },
  cnsendbirdReducer: { cnsendbird },
  cnuserReducer: { cnuuid }
}) => ({
  current_cnchannel,
  cnsendbird,
  cnuuid
});

export default connect(mapStateToProps)(HisUserMessage);
import React, { useState } from 'react';
import { Card, Avatar, CardContent } from "@mui/material";
import { Image } from 'antd';
import './mySKU.css';

function formatTimestamp(timestamp) {
	const date = new Date(timestamp);
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	return `${hours}:${minutes}`; // 返回时:分格式
  }


const HisSkuMessage = (props) => {
	const message = props.message;
	const nickname = message.sender ? message.sender.nickname : '';
	console.log(message);
	const skus = JSON.parse(message.data);
	const formattedTime = formatTimestamp(message.createdAt);


  return (
<div>
      <Card sx={ { boxShadow : 'none' } } >


<CardContent>



		<div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            size={37}
            alt=""
            src={message.sender.plainProfileUrl}
            style={{ marginRight: '8px' }} // 添加右边距以分隔头像和文本
          />
          <div>
            <strong className="cont_text2">{nickname}</strong>
            <span className="cont_text1" style={{ marginLeft: '8px' }}>{formattedTime}</span>
          </div>
        </div>



		<ul className="ufl" style={{ listStyleType: 'none', padding: 0 }}>
      {skus.map((sku) => (
        <li key={sku.code} className="sku-item">
          <div className="sku-info">
            <div className="attachment right-file">
              <Image width={120} src={sku.imgSrc} alt={sku.attribute} />
            </div>
            <div className="sku-details">
              <span className="sku-attribute">{sku.attribute}</span>
              <div className="t05">
                <span className="red">¥ {sku.price.toFixed(2)}</span>
                <span className="cont_text4">{sku.code}</span>
              </div>
            </div>
          </div>
        </li>
      ))}
</ul>


















</CardContent>

</Card>
    </div>


  );
};


export default HisSkuMessage;
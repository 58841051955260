import React from "react";
import { connect } from 'react-redux';
import './index.css'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardMedia,
  Avatar,
  Button,
  Link,
  Typography
} from "@mui/material";
import styles from './message.module.css';
import { allowedFiles, allowedVideo, EMOJI } from '../pages/user/home/helper';
import { Image } from 'antd';

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`; // 返回时:分格式
}

function HisFileMessage(props) {
  const message = props.message;
  const userId = props.cnuuid;
  const currentChannel = props.current_cnchannel;
  const nickname = message.sender ? message.sender.nickname : '';
  const formattedTime = formatTimestamp(message.createdAt);
console.log(formattedTime);
console.log(message);
  const onDeleteMessage = () =>{
    currentChannel.deleteMessage(message)
  }
    //文本消息
    const textMessage = () => {
      let msg = props.message.message;
      const reg = new RegExp(`${EMOJI.symbolReg}(\\d{3})`, 'g');
      msg = msg ? msg.replace(reg, EMOJI.link(EMOJI.symbol+'$1')) : '';
      msg = msg.replace(props.keyword, '<span style="color:#b70b1b;font-weight:bold;">'+props.keyword+'</span>')

      return <div className={`message-content border p-3 ${styles.message_reformat}`} id={'history-content-'+props.message.message_id} dangerouslySetInnerHTML={{ __html: msg }} />
  };

 /**文件消息 */
  const fileMessage = () => {
      const fileUrl = message.url;
      const fileData = fileUrl.split('/');
      const fileName = fileData[fileData.length - 1];
      const files = fileName.split('.');
      let fileExtension = files[files.length - 1].toLowerCase();
      
      if (fileExtension.length > 30) {
        const ext = fileExtension.split('?');
        fileExtension = ext[0];
      } 
      //console.log(message);
      let msgComponent;
      if (allowedFiles.hasOwnProperty(fileExtension)) {//可下载或可浏览文件
          msgComponent = (
              <a href={fileUrl} target="_blank" rel="noreferrer" className="attachment">
                  <div className="media mt-2">
                      <div className="avatar me-2">
                          <img className={styles.file_type} src={allowedFiles[fileExtension]} alt="" />
                      </div>
                      <div className="media-body overflow-hidden">
                          <h6 className="text-truncate mb-0">{props.message.file.name}</h6>
                          {/* <span className="file-size">2.7 mb</span> */}
                      </div>
                  </div>
              </a>
          );
      } else if (allowedVideo.includes(fileExtension)) {//视频文件
          msgComponent = (
              <div className="attachment right-file">
                  <video width="184" controls>
                      <source src={fileUrl} />
                  </video>
              </div>
          );
      } else {//图片文件
          msgComponent = (
              <div className="attachment right-file">
                  <Image width={120} src={fileUrl} />
              </div>
          );
      }

      return <div className={`message-content border p-3 ${styles.message_reformat}`} id={'history-content-'+props.message.messageId}>{msgComponent}</div>;
  } 

  /* 
  const fetchFileSize = async (url) => {
    try {
        const response = await fetch(url, { method: 'HEAD' });
        console.log(response);
        const size = response.headers.get('Content-Length');
        return size ? (size / (1024 * 1024)).toFixed(2) + ' MB' : '未知大小';
    } catch (error) {
        console.error('获取文件大小失败:', error);
        return '无法获取大小';
    }
};
 */

 const officefileMessage = () => {
  const fileUrl = message.url;

  const fileData = fileUrl.split('/');
  const fileName = fileData[fileData.length - 1];
  const files = fileName.split('.');
  let fileExtension = files[files.length - 1].toLowerCase();

  if (fileExtension.length > 30) {
    const ext = fileExtension.split('?');
    fileExtension = ext[0];
  }
  console.log(message);
  let msgComponent;
  if (allowedFiles.hasOwnProperty(fileExtension)) {
    //const fileSize = await fetchFileSize(fileUrl);
      msgComponent = (
      <a href={fileUrl} target="_blank" rel="noreferrer" className="attachment">
				<div className="bgd3 fr">
					<ul style={{ display: 'flex', alignItems: 'center' }}>
						<li style={{width: '170px'}} className="t05">
            {message.name}
							<div className="cont_text3 t03"></div>
						</li>
						<li><img src={allowedFiles[fileExtension]} alt="" /></li>
					</ul>
					<div className="cb"></div>
				</div>
        </a>

      );
  } else {//图片文件
      msgComponent = (
          <div className="attachment right-file">
              <Image width={120} src={fileUrl} />
          </div>
      );
  }

  return <div className={`message-content border p-3 ${styles.message_reformat}`} id={'history-content-'+props.message.messageId}>{msgComponent}</div>;
}
  /**截图发送的消息 */
  const clipboardImageMessage = () => {
      let imageData = JSON.parse(props.message.data);
      let images = imageData.hasOwnProperty('clipboardImages') ? imageData.clipboardImages : imageData;

      return (
          <div className={`message-content border p-3 ${styles.message_reformat}`} id={'history-content-'+props.message.message_id}>
              <div className="attachment right-file">
                  {images.map((item, key)=>(
                      <Image key={key} width={120} src={item} />
                  ))}
              </div>
          </div>
      );
  };

  const messageRender = () => {
    let msgContent;
    const file_type = props.message.type.split('/');
    console.log(file_type[0]);
    if (file_type[0] === 'video') {
        msgContent = fileMessage();
    } else if (file_type[0] === 'image') {
        msgContent = fileMessage();
    } else if (file_type[0] === 'application') {
      msgContent = officefileMessage();
  } else {
      console.log(message);
        msgContent = textMessage();
    }   

    return msgContent;
}


  return (
    <div className="file-message">
      <Card sx={ { boxShadow : 'none' } } >

        <CardContent>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            size={37}
            alt=""
            src={message.sender.plainProfileUrl}
            style={{ marginRight: '8px' }} // 添加右边距以分隔头像和文本
          />
          <div>
            <strong className="cont_text2">{nickname}</strong>
            <span className="cont_text1" style={{ marginLeft: '8px' }}>{formattedTime}</span>
          </div>
        </div>

        <div class="img1"> {messageRender()} </div>

      </CardContent>


      </Card>
    </div>
  );
}

const mapStateToProps = ({
  cnchannelReducer: { current_cnchannel },
  cnsendbirdReducer: { cnsendbird },
  cnuserReducer: { cnuuid }
}) => ({
  current_cnchannel,
  cnsendbird,
  cnuuid
});


export default connect(mapStateToProps)(HisFileMessage);
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import GroupChannel from '@sendbird/uikit-react/GroupChannel';
import {
  sendbirdSelectors,
  useSendbirdStateContext,
} from "@sendbird/uikit-react";
import CustomizedMessageItem from "./CustomizedMessageItem";
import CustomizedMessageInput from "./CustomizedMessageInput";
//import ChatHistory from '../side/ChatHistory'; // 历史查询窗口组件
import ChatWindow from '../side/ChatWindow';
import { ChatActions } from "../../../actions/common/ChatActions";
import { sendBotMessage } from '../../../actions/common/sendbird'
import html2canvas from 'html2canvas';
import SendbirdChat from "@sendbird/chat";
import { GroupChannelModule, GroupChannelHandler } from "@sendbird/chat/groupChannel";
import { APP_ID } from './const';
import "./mainComponent.css";

// 定义一个临时的 CustomChannelHeader
const CustomChannelHeader = () => (
  <div className="custom-channel-header">
  </div>
);

function MainComponent(props) {
  const { userId, sb, channelUrl, setChannelURL, setReduceChannel } = props;
  const [currentChannel, setCurrentChannel] = useState(null);
  const store = useSendbirdStateContext();
  const updateUserMessage = sendbirdSelectors.getUpdateUserMessage(store);
  const [screenshotFile, setScreenshotFile] = useState(null); // 截图文件状态
  const [channelReady, setChannelReady] = useState(false);
  const [groupChannel, setGroupChannel] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // 显示弹窗
  const showModal = () => {
    setIsModalVisible(true);
  };
  // 隐藏弹窗
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  // 控制消息的可见性
  const [isMessagesHidden, setMessagesHidden] = useState(false);

  // 截图处理函数
  const handleScreenshot = async () => {
    // 在截图前将消息内容隐藏
    setMessagesHidden(true);

    // 使用 requestAnimationFrame 确保下一帧执行截图
    requestAnimationFrame(async () => {
      try {
        const canvas = await html2canvas(document.body); // 截图整个页面
        const imgData = canvas.toDataURL('image/png');
        const screenshotFile = new File([dataURLtoBlob(imgData)], 'screenshot.png', { type: 'image/png' });
        setScreenshotFile(screenshotFile);  // 更新截图文件状态
        //setFile(screenshotFile);
        //setFilePreview({ url: imgData, type: 'image' });
      } catch (error) {
        console.error('Screenshot failed:', error);
      } finally {
        // 截图完成后恢复消息的可见性
        setMessagesHidden(false);
      }
    });
  };


  // 将 Base64 URL 转换为 Blob
  const dataURLtoBlob = (dataURL) => {
    const [header, base64Data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(base64Data);
    const array = Uint8Array.from(binary, char => char.charCodeAt(0));
    return new Blob([array], { type: mime });
  };


  // 初始化频道，处理令牌过期和本地存储无效数据的逻辑
  const initializeChannel = async () => {
    try {
        const channel = await sb.groupChannel.getChannel(channelUrl);
        setReduceChannel(channel);
        setGroupChannel(channel);
        setChannelURL(channelUrl);
        //setSendBird(sb);

        // 注册消息处理器
        //registerChannelHandler(sb, channel);
        const welcomeMessage = "您好 欢迎来到1688官方合作伴CNINSIDER!请问有什可以帮助您的？";
        await sendCnBotMessage(channel, welcomeMessage);
    } catch (error) {
      console.error(`Sendbird 初始化失败: ${error.message}`);
    }
  };

  // 注册消息处理器
  const registerChannelHandler = (sbInstance, channel) => {
    const channelHandler = new GroupChannelHandler();
    

    channelHandler.onMessageReceived = async (receivedChannel, message) => {
      if (receivedChannel.url === channel.url) {
        console.log(message);
        console.log(receivedChannel.url);

        //const response = ''; // 可根据需要实现自动回复
        //if (response) {
        //  await sendMessage(receivedChannel, response);
        //}
      }
    };
    sbInstance.groupChannel.addGroupChannelHandler('UNIQUE_HANDLER_ID', channelHandler);
  };

  // 发送消息
  const sendMessage = async (channel, text) => {
    const messageParams = {
      message: text,
      customType: 'faq_auto_reply',
      data: JSON.stringify({ source: 'bot' }),
    };
    try {
      await channel.sendUserMessage(messageParams);
    } catch (error) {
      console.error('消息发送失败:', error);
    }
  };
  const sendCnBotMessage = (channel, text) => {
    const messageParams = {
      "message": text,
      "channel_url": channel.url,
      "custom_type": "faq_auto_reply"
    };
  
    try {
      const sentMessage = sendBotMessage(messageParams);
      console.log('Message sent by cnchat_bot_1:', sentMessage);
    } catch (error) {
      console.error('Failed to send message by cnchat_bot_1:', error);
    }
  };
  // 断开连接并释放资源
  const disconnectSendbird = () => {
    if (sb) {
      sb.disconnect()
        .then(() => console.log('Sendbird 已断开连接'))
        .catch(error => console.error('断开连接时出错: ', error));
    }
  };

  // 渲染逻辑
  useEffect(() => {
    if (channelUrl && sb) {
      initializeChannel();
    } 
    //return () => disconnectSendbird(); // 清理函数
  
  }, [channelUrl, sb]);

  // 监听 groupChannel 更新
  useEffect(() => {
    if (groupChannel && groupChannel.url) {
      setChannelReady(true);
    } else {
      setChannelReady(false);
    }
  }, [groupChannel]);

  // 临时渲染 CustomChannelHeader
  if (!channelReady) {
    return null; // 如果 `channelReady` 为 false，直接返回 null，去掉加载信息
  }

  return (
    <div className="channel-wrap">
      <div className="channel-chat">
        <GroupChannel
          renderChannelHeader={() => <CustomChannelHeader />}
          channelUrl={channelUrl}
          renderMessage={({ message }) => (
            <div style={{ visibility: isMessagesHidden ? 'hidden' : 'visible' }}>
            <CustomizedMessageItem
              userId={userId}
              message={message}
              updateUserMessage={updateUserMessage}
            />
          </div>
          )}
          renderMessageInput={() => <CustomizedMessageInput channelurl={channelUrl} screenshotFile={screenshotFile} onScreenshot={handleScreenshot} showModal={showModal} />}
        />
        
        <ChatWindow visible={isModalVisible} onClose={handleCancel} />
      </div>
    </div>
  );
}

// 从 Redux 中映射 state 到 props
const mapStateToProps = ({
  cnchannelReducer: { current_cnchannel },
  cnsendbirdReducer: { cnsendbird },
  cnuserReducer: { cnuuid, cnchannelurl }
}) => ({
  current_cnchannel,
  cnsendbird,
  cnuuid,
  cnchannelurl
});

const mapDispatchToProps = {
  setChannelURL(url) {
    return {
      type: 'SET_CNCHANNELURL',
      payload: url,
    };
  },
  setReduceChannel(channel) {
    return {
      type: 'SET_CURRENT_CNCHANNEL',
      payload: channel,
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);

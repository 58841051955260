import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import './customizedMessageInput.css';
import { Popover } from 'antd';
import {
  ToolButton,
  setEndOfContenteditable,
  fileTypeIcons,
  EmojiList
} from './helper';
function CustomizedMessageInput(props) {
  const [channelDraft, setChannelDraft] = useState({}); // 草稿箱映射表
  const [filePreview, setFilePreview] = useState(null);
  const [file, setFile] = useState(null);
  const [messageSending, setMessageSending] = useState(false); // 消息发送状态
  const clipInputRef = useRef(null); // 消息输入框
  const context = useGroupChannelContext();

  // 初始化时处理来自父组件的截图文件
  useEffect(() => {
    if (props.screenshotFile) {
      setFile(props.screenshotFile); // 更新文件状态
      setFilePreview({ url: URL.createObjectURL(props.screenshotFile), type: 'image' }); // 更新预览状态
    }
  }, [props.screenshotFile]);
  // 初始化时设置草稿内容

  
  // 更新草稿内容
  const setDraft = useCallback(() => {
    const _key = props.cnchannelurl;
    const _val = clipInputRef.current.innerHTML;
    setChannelDraft(prevDraft => ({ ...prevDraft, [_key]: _val }));
  }, [props.cnchannelurl]);

  
  // 移动光标到末尾
  const moveCursorToEnd = () => {
    const el = clipInputRef.current;
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(el);
    range.collapse(false); // Move cursor to the end
    sel.removeAllRanges();
    sel.addRange(range);
    el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
  };

  const moveCursorToNext = (position) => {
    const el = clipInputRef.current;
    const range = document.createRange();
    const sel = window.getSelection();  
    // 检查是否有子节点并根据当前位置移动光标
    const textNode = el.firstChild || el;
    const nextPos = Math.min(position + 1, textNode.textContent.length);  
    range.setStart(textNode, nextPos); // 设置光标到下一个位置
    range.collapse(true); // 光标设置到起始位置，防止选择范围
    sel.removeAllRanges();
    sel.addRange(range);
  
    el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
  };


  // 处理光标点击
  const handleClick = (e) => {
    // 这里不做光标移动的处理
  };
  // 设置光标位置
  const setCursorPosition = (position) => {
    const el = clipInputRef.current;
    const range = document.createRange();
    const sel = window.getSelection();
    range.setStart(el.firstChild || el, position);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  };

  const saveCursorPosition = () => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    return { start: range.startOffset, end: range.endOffset };
  };

  const restoreCursorPosition = (position) => {
    const el = clipInputRef.current;
    const range = document.createRange();
    const sel = window.getSelection();
    const textNode = el.firstChild; // Assuming the first child is a text node
    range.setStart(textNode, position.start);
    range.setEnd(textNode, position.end);
    sel.removeAllRanges();
    sel.addRange(range);
  };
  // 添加表情到输入框
  const addEmoji = useCallback((emoji) => {
    const clipInput = clipInputRef.current;
    clipInput.innerHTML = clipInput.innerHTML.trim() + emoji;
    setEndOfContenteditable(clipInput);
    setDraft();
    //moveCursorToEnd();
  }, [setDraft]);

  // 处理截图
  const handleScreenshot = async () => {
    try {
      await props.onScreenshot();
    } catch (error) {
      console.error('截图失败:', error);
    }
/* 
    setTimeout(() => {

      console.log('=================');
      props.onScreenshot(false);
    }, 3000); // 3000毫秒 = 3秒
 */
  };

  // 将 Data URL 转换为 Blob
  const dataURLtoBlob = (dataURL) => {
    const [header, base64Data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(base64Data);
    const array = Uint8Array.from(binary, char => char.charCodeAt(0));
    return new Blob([array], { type: mime });
  };

  // 清空输入框
  const clearInput = useCallback(() => {
    setChannelDraft(prevDraft => ({ ...prevDraft, [props.channelurl]: '' }));
    clipInputRef.current.innerHTML = '';    
    setFile(null);
    setFilePreview(null);
    moveCursorToEnd();
  }, [props.channelurl]);

  // 发送消息
  const handleSendMessage = async () => {
    const msg = clipInputRef.current.innerHTML.trim();
    if ((msg === '' && !file) || messageSending) return;

    setMessageSending(true);
    try {
      if (msg !== '') {
        await context.sendUserMessage({ message: msg });
      }
      if (file) {
        await context.sendFileMessage({ file, fileName: file.name });
      }
      clearInput();
    } catch (error) {
      console.error('发送消息失败:', error);
    } finally {
      setMessageSending(false);
    }
  };

  // 处理文件选择
  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);

/* 
      const type = selectedFile.type.startsWith('image/') ? 'image' :
                   selectedFile.type.startsWith('video/') ? 'video' : 
                   'office';
      setFilePreview({ url: URL.createObjectURL(selectedFile), type, name: selectedFile.name });
 */
      const type = selectedFile.type;
      const isImage = type.startsWith('image/');
      const isVideo = type.startsWith('video/');

      if (isImage || isVideo) {
        setFilePreview({ url: URL.createObjectURL(selectedFile), type: isImage ? 'image' : 'video', name: selectedFile.name });
      } else {
        setFilePreview({ url: fileTypeIcons[type] || '/img/file-icon.png', type, name: selectedFile.name });
      }
    }
  };
  const getCaretPosition = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return 0;
  
    const range = selection.getRangeAt(0);
    let position = range.startOffset;
    let node = range.startContainer;
  
    // 循环检查，确保我们获取的是 `clipInputRef` 内部的精确光标位置
    while (node && node !== clipInputRef.current) {
      let previousSibling = node.previousSibling;
  
      // 计算所有前面兄弟节点的长度，来获取光标的准确位置
      while (previousSibling) {
        position += previousSibling.textContent.length;
        previousSibling = previousSibling.previousSibling;
      }
      node = node.parentNode;
    }
    return position;
  };
  
  // 判断是否在输入框中间
  const isInMiddle = (pos, length) => pos > 0 && pos < length;
  
  // 处理输入事件
  const handleInput = (e) => {
    const clipInput = clipInputRef.current;
    const content = clipInput.textContent || '';
    const pos = getCaretPosition(); // 当前光标位置
    const length = content.length;  // 当前输入框文本的总长度
/*   
    // 判断输入位置
    if (pos === 0) {
      console.log("在开头输入或删除");
    } else if (isInMiddle(pos, length)) {
      console.log("在中间输入或删除");
    } else if (pos === length) {
      console.log("在最后输入或删除");
    }
   */
    setDraft(); // 更新草稿内容
  };
  
  // 处理键盘事件，区分输入和删除
  const handleKeyDown = (e) => {
    // 处理回车键发送消息
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
      return;
    }
    // 处理Backspace和Delete清空输入框
    if (e.key === 'Backspace' || e.key === 'Delete') {
      clearInput();
    }

  };
  

  // 表情弹窗
  const emojiWrap = (
    <div className="emojis">
      {EmojiList.map(item => (
        <b className="pointer" key={item.id} onClick={() => addEmoji(item.emoji)}>{item.emoji}</b>
      ))}
    </div>
  );

  return (
    <div className="custom-message-input">
      <div className="input-wrapper">
        <div className="input-tools">
        <div className="container">
            <div className="button-group">
            <Popover placement="top" content={emojiWrap} trigger="click">
              <ToolButton src="Group1" alt="Emoji" />
            </Popover>
            <ToolButton src="Group2" alt="图片/视频" onClick={() => document.getElementById('mediaInput').click()} />
            <ToolButton src="Group3" alt="Screenshot" onClick={handleScreenshot} />
            <ToolButton src="Group4" alt="文档" onClick={() => document.getElementById('officeInput').click()} />
            </div>
            <button className="button right-button" onClick={props.showModal}>
              <img src="/img/chat/Group5.png" alt="His" className="his-icon" />
            </button>
        </div>

          <input id="mediaInput" type="file" accept="image/*,video/*" onChange={handleFileSelect} style={{ display: 'none' }} />
          <input id="officeInput" type="file" accept=".txt,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.pdf,.zip,.rar" onChange={handleFileSelect} style={{ display: 'none' }} />

          <div className="message-preview">
            {filePreview && (
              <div className="file-preview">
                {filePreview.type === 'image' ? (
                  <div className="media-container">
                    <img src={filePreview.url} alt="预览" className="media-preview" />
                    <span className="file-name">{filePreview.name}</span>
                  </div>
                ) : filePreview.type === 'video' ? (
                  <div className="media-container">
                    <video src={filePreview.url} controls className="media-preview" />
                    <span className="file-name">{filePreview.name}</span>
                  </div>
                ) : (
                  <div className="file-container">
                    <img src={filePreview.url} alt="File Icon" className="file-icon" />
                    <span className="file-name">{filePreview.name}</span>
                  </div>
                )}
              </div>
            )}
          </div>

        <div
          className="write_box"
          ref={clipInputRef}
          contentEditable
          onInput={setDraft}
          onKeyDown={handleKeyDown}
          style={{ whiteSpace: 'pre-wrap' }} // 确保换行符正常处理
        ></div>
      </div>
      </div>
      <button className="send-button" onClick={handleSendMessage} disabled={messageSending}>发送</button>
    </div>
  );
}


export default CustomizedMessageInput;
import React from 'react';

const SKUItem = ({ attribute, price, code, imgSrc, isSelected, onSelect }) => {
  return (
    <div className="group">
      <input 
        type="checkbox" 
        checked={isSelected} 
        onChange={onSelect} 
        className="checkbox" 
      />
      <img className="img" alt="Img" src={imgSrc} />
      <div className="details">
        <div className="m" dangerouslySetInnerHTML={{ __html: attribute }} />
        <div className="text-wrapper">{code}</div>
      </div>
    </div>
  );
};

export default SKUItem;

export const SendbirdApi = {
    //用户相关api
    get_user: user_id => `/users/${user_id}`,//get
    create_user: '/users',//post
    update_user: user_id => `/users/${user_id}`,//put
    delete_user: user_id => `/users/${user_id}`,//delete

    //channel相关api
    my_group_channels: user_id => `/users/${user_id}/my_group_channels`,//get
    group_channels: channel_url => `/group_channels/${channel_url}`,//put
    group_channel: channel_url => `/group_channels/${channel_url}`,//get
    create_channel: '/group_channels',//post
    bot_join_channel: bot_userid => `/bots/${bot_userid}/channels`,//post
    invite: channel_url => `/group_channels/${channel_url}/invite`,//post
    leave: channel_url => `/group_channels/${channel_url}/leave`,//put
    delete_channel: channel_url => `/group_channels/${channel_url}`,//delete

    //message相关api
    delete_message: (channel_url, message_id) => `/group_channels/${channel_url}/messages/${message_id}`,//delete
    emojis: '/emojis',//get|post
    emoji_categories: '/emoji_categories',//get|post
    messages: channel_url => `/group_channels/${channel_url}/messages`,//get|post
    mark_as_read: channel_url => `/group_channels/${channel_url}/messages/mark_as_read`,//put
    search_messages: `/search/messages`,//get

    //统计相关api
    ccu: '/applications/ccu',//get
    connections: '/applications/peak_connections',//get
};